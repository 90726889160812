@import '../../styles/variables';
@import '../../styles/mixins';

.feedBottom {
  background-image: linear-gradient(180deg, $themeColor1 10%, $themeColor2 100%);
  border-radius: 8px;
  padding: $space-4 $space-3;
  margin-top: $space-5;
  text-align: center;

  h2 {
    color: $themeTextColor;
    font-family: $base-font;
    font-size: $text-4a;
    font-weight: $bold;
    margin-bottom: 0;
  }

  @include breakpoint('lg') {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    min-height: 226px;
    h2 {
      font-size: $text-3b;
      margin: 0 auto 36px;
      max-width: 436px;
    }
    &.sponsored {
      min-height: 158px;
      h2{
        margin-bottom: 0;
      }
    }
  }
}

.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: $text-6;
  line-height: 115%;
  font-weight: $bold;
  color: $themeTextColor;
  border-radius: 20px;
  border: 2px solid $themeTextColor;
  padding: $space-1 $space-1a;
  min-width: 240px;
  margin: $space-3 0;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
  .icon {
    margin: -1px $space-05 0 0;
    width: 18px;
    height: 18px;
    path {
      stroke: $themeTextColor;
      transition: stroke 0.2s ease-in-out;
    }
  }
  &:hover {
    background-color: $themeTextColor;
    color: $themeColor1;
    .icon {
      path {
        stroke: $themeColor1;
      }
    }
  }
}
