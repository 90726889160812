@import '../../../styles/variables';
@import '../../../styles/mixins';

:where(.motd) {
  h3 {
    margin: 0 0 $space-2 0;
    font-size: 1.5rem; // @todo
    overflow: hidden;
  }

  figure {
    aspect-ratio: 3/2;
    border-radius: 8px;
    overflow: hidden;

    img,
    video {
      object-fit: cover;
      width: 100%;
    }

    margin-bottom: $space-2;
  }

  @include breakpoint('sm') {
    h3,
    figure {
      margin-bottom: $space-1;
    }
  }

  @include breakpoint('lg') {
    h3 {
      font-size: $text-5;
    }
  }
}

.emojiReactions {
  --reactionsFontSize: #{$text-5a}; // 16px
  --reactionsListItemFontSize: 15px;
  --reactionsListItemSize: #{$text-3}; // 26px
  --reactionsStatsSpacing: #{$space-1a}; // 12px

  @include breakpoint('sm') {
    --reactionsStatsSpacing: #{$space-1a};
    --reactionsFontSize: #{$text-5a};
    --reactionsListItemFontSize: #{$text-5a};
    --reactionsListItemSize: #{$text-3};

    float: right;
  }

  --reactionsUserReaction: #{$color-light-violet};
}
