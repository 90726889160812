@import '../../../styles/variables';
@import '../../../styles/mixins';

@keyframes ticker {
  0%   { background-position-x: 0; }
  /*
    To keep the animation speed proportional at any screen width, subtract the same value in VW
    units as percentage: (N% - Nvw)
    be
   */
  100% { background-position-x: calc(225% - 225vw); }
}

@keyframes rotate {
  0%   { transform: rotate(0); }
  100% { transform: rotate(240deg); }
}

.headline {
  $animationSettings: 5s forwards cubic-bezier(0.85, 1, 0.85, 1);
  position: relative;
  margin-top: $space-3;
  color: $color-black;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: baseline;

  h2 {
    font-size: $text-3b; // 24px
  }

  .spinner {
    display: none;
  }

  @include breakpoint('sm') {
    height: 30px;
    background: url(./img/trending.svg) repeat-x;
    background-size: 74px 30px;
    animation: ticker #{$animationSettings};
    margin-top: $space-2;
    margin-left: -#{$space-2};
    margin-right: -#{$space-2};
    display: block;

    @media (prefers-reduced-motion) {
      animation: none;
    }

    h2,
    button,
    .cta {
      display: none;
    }

  }

  @include breakpoint('lg') {
    height: 1px; // The H2 needs to be a non zero height to be read by screen readers
    background: none;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;

    .spinner {
      background-color: white;
      border-radius: 100vw;
      display: initial;
      outline: 11px solid white;
      position: absolute;
      transform: translate3d(calc(-50% + $space-05), calc(-50% + $space-2), 0);
      z-index: 1;
      box-shadow: -2px -2px 2px white, 2px 2px 2px #fff;

      g {
        animation: rotate #{$animationSettings};
        transform-origin: 50% 50%;

        @media (prefers-reduced-motion) {
          animation: none;
        }
      }
    }
  }
}

.trendingPosts {
  display: grid;
  flex-direction: row;
  padding: $space-2 0 $space-3;
  list-style: none;
  grid-gap: $text-3;

  @include breakpoint('sm') {

    // Hide the 5th item and beyond on breakpoints larger than mobile
    > li:nth-child(n+5) {
      display: none;
    }

    grid-template-columns: repeat(2, 1fr);
    padding-bottom: $space-5;
  }

  @include breakpoint('lg') {
    grid-template-columns: 1.55fr repeat(3, 1fr);
  }

  /**
   * These styles will only apply up to the `sm` breakpoint, will not apply on screens larger than
   * `sm`.
   */
  @include breakpoint('sm', 'max') {
    $imageWidth: 163px;

    .secondaryCard {
      position: relative;
      padding-left: calc($imageWidth + $space-1a);
      min-height: #{ceil($imageWidth * (2/3))};

      > article {
        position: static;
      }

      a {
        position: static;

        > div {
          padding: 0;
        }
      }

      h3 {
        font-size: $text-6;
      }

      figure {
        position: absolute;
        left: 0;
        width: $imageWidth;
      }
    }
  }
}

.cta {
  font-weight: $bold;
  font-family: $base-font;
  font-size: $text-5;
  color: var(--color-purple-primary);
  display: inline-flex;
  padding: 0;
  align-items: center;

  @include breakpoint('sm') {
    justify-content: end;
  }

  @include breakpoint('lg') {
    position: absolute;
    bottom: $space-2;
    right: 0;
  }

  &:hover {
    color: var(--color-accent-pink);

    svg path {
      stroke: var(--color-accent-pink);
    }
  }
}

a.ctaBottom {
  color: $color-white;
  border-radius: 29px;
  background: var(--color-purple-primary);
  line-height: 1;
  padding: .5rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: $color-feed-pink;
  }
  &:disabled {
    background-color: $color-feed-gray-2;
  }
}

button.ctaBottom,
a.ctaBottom {
  min-height: 40px;
  width: 100%;
  font-weight: $bold;
  margin-bottom: $space-3;

  svg {
    display: none;
  }

  @include breakpoint('sm') {
    width: auto;
    background: none;
    color: var(--color-purple-primary);
    display: inline-flex;
    align-items: center;
    padding: $space-1a 0;
    float: right;
    margin-top: -2.5rem;
    margin-bottom: 0;
    min-height: auto;

    &:hover {
      color: var(--color-accent-pink);
      background: none;

      svg path {
        stroke: var(--color-accent-pink);
      }
    }

    svg {
      display: initial;
    }
  }
}
