@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.shoppingPost {
  display: flex;
  gap: $space-1a;

  @include breakpoint('sm') {
    margin-bottom: 20px;
    width: 100%;
  }

  figure {
    border-radius: 8px;
    flex: 0 0 auto;
    overflow: hidden;
    width: 160px;

    @include breakpoint('sm') {
      border-radius: 4px;
      height: 90px;
      width: 90px;
    }

    img {
      height: 100%;
      object-fit: cover;
      vertical-align: top;
    }
  }

  .itemLabel {
    @include breakpoint('sm', 'max') {
      // visually hidden
      border: 0;
      display: block;
      height: 0;
      overflow: hidden;
      position: absolute;
      text-align: left;
      text-indent: -9999em;
      width: 0;
    }


    @include breakpoint('sm') {
      color: var(--color-accent-pink);
      font-family: Calistoga, cursive;
      font-size: $text-7;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      letter-spacing: 0.12px;
      text-transform: uppercase;
      vertical-align: top;
    }
  }

  h3 {
    color: $color-black;
    font-family: Proxima Nova;
    font-size: $text-6;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin: 0;
    text-transform: capitalize;

    &:hover {
      color: var(--color-purple-primary);
    }

    @include breakpoint('sm') {
      font-size: 1rem;
      font-weight: 600;
    }
  }
}
