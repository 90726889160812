@import '../../styles/variables';
@import '../../styles/mixins';


.contentList {
  position: relative;
  display: grid;
  list-style: none;
  padding: 0;
  transform: none;
  /**
   Define a single area for the cell. Placing transitioning slides in the same cell allows them to
   overlap each other without needing to position them absolutely. The slides will remain in the
   document layout flow, affecting height and width.
  */
  grid-template-areas: "content";

  will-change: transform;
}

.content {
  position: relative;
  grid-area: content;
  will-change: opacity, top, transform;
}

.next {
  transform: translate3d(calc(100% * var(--content-switch-direction, 1)), 0, 0);
}
.transitionPending {
  transition: transform var(--content-switch-duration, 500ms) ease-in-out;
  transform: translate3d(calc(-100% * var(--content-switch-direction, 1)), 0, 0);

  .content {
    transition: opacity var(--content-switch-duration, 500ms) ease-in-out;
  }

  .active {
    position: absolute;
    top: var(--content-scroll-offset, 0px);
  }
}

@mixin contentTransitionStyles {
  .next {
    opacity: 0;
    transform: none;
    z-index: 2;
    transition-timing-function: ease-out;
  }

  .transitionPending {
    transform: none;

    .active {
      opacity: 0;
    }

    .next {
      opacity: 1;
    }
  }
}

@media (prefers-reduced-motion) {
  @include contentTransitionStyles;
}

@include breakpoint('md') {
  @include contentTransitionStyles;
}

.transitionLeft {
  --content-switch-direction: -1;
}

.transitionRight {
  --content-switch-direction: 1;
}
