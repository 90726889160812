@import '../../styles/mixins';
@import '../../styles/variables';

.container {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: $space-3 0;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  text-align: center;
  background: $color-white;

  @include breakpoint('sm') {
    padding: $space-4 $space-2;
  }

  @include breakpoint('lg') {
    max-width: calc(1240px + 2rem);
    padding: calc($space-4 + $space-1) $space-2;
  }

  h2 {
    font-family: Proxima Nova;
  }

  .icon {
    display: none;
  }

  .content {
    align-items: center;
    padding: $space-1 $space-2 $space-4;
    margin-bottom: $space-3;
    width: 100%;
    z-index: 1;
    background-color: $color-aqua;
    border-radius: $space-1 $space-1 22px 22px;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      bottom: -8px;
      left: 0;
      width: 100%;
      height: 32px;
      mask-image: url('../../static/img/waves/wave-size-8-bg.svg');
      background-color: var(--wave-color, $color-feed-purple);
      mask-position: 0 100%;
      mask-size: 100% 32px;
      mask-repeat: no-repeat;
    }

    @include breakpoint('sm') {
      border-radius: 0 5px 5px 0;
      margin-bottom: 0;
      margin-left: 8px;
      padding: $space-1 $space-4 $space-1 $space-1;
      &:after {
        height: 100%;
        width: auto;
        top: 0;
        bottom: auto;
        left: -8px;
        aspect-ratio : 1 / 1;
        transform: rotate(90deg);
        mask-size: 100% 24px;
        mask-position: 100% 100%;
        background-repeat: no-repeat;
        mask-image: url('../../static/img/waves/wave-size-5-bg.svg');
      }
    }

    @include breakpoint('lg') {
      display: flex;
      justify-content: center;
      padding: $space-2 $space-4 $space-2 $space-1;
      &:after {
        mask-image: url('../../static/img/waves/wave-size-3-bg.svg');
      }
    }
  }

  .button {
    background-color: var(--color-purple-primary);
    border: 1px solid transparent;
    border-radius: 18px;
    box-shadow: none;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: Proxima Nova,Helvetica,Arial,sans-serif;
    font-weight: 700;
    margin: 0 auto $space-2;
    min-height: 40px;
    outline: none;
    padding: 0.5rem 1rem;

    &:hover {
      background-color: var(--color-accent-pink);
    }
    @include breakpoint('lg') {
      margin: $space-05 0;
    }

    &.signup {
      border-radius: 0 18px 18px 0;
      margin-right: 20px;
      text-wrap: nowrap;
    }
  }

  .login {
    margin: $space-2 auto $space-1;
    display: block;

    @include breakpoint('sm') {
      margin: $space-2 auto;
    }

    @include breakpoint('lg') {
      margin: $space-05 0;
    }
  }

  .title,
  .body {
    width: fit-content;
    text-align: center;
  }

  .title {
    font-size: $text-5;
    font-weight: 800;
    line-height: 20px;
    margin: $space-2 auto $space-1;

    @include breakpoint('lg') {
      margin: 0;
    }
  }

  .body {
    margin: $space-1 auto $space-2;
    line-height: 19px;
    font-weight: 600;

    @include breakpoint('lg') {
      margin: 0 1rem;
    }
  }

  .emailInput {
    border: 1px solid #aaaaaa;
    border-right: none;
    line-height: 33px;
    padding-left: 16px;
    max-width: 250px;
    @media (min-width: 400px) {
      width: 250px;
    }
  }

  .signupForm {
    display: flex;
    margin: 20px auto;
    max-width: 368px;
    width: fit-content;

    @include breakpoint('lg') {
      margin: $space-05 0;
    }
  }
}

.visuallyHidden {
  visibility: hidden;
  position: absolute;
}

.membershipPromo {
  padding: 0;
  .content {
    text-align: left;
    background-color: #ffe3d4;
    border-radius: 0;
    padding: 0.75rem 1rem 0.75rem 3.5rem;
    margin: 0;
    &:after {
      display: none;
      background-color: #D44116;
    }
    .title,
    .body {
      width: auto;
      text-align: left;
      margin: 0;
      font-size: $text-6;
      line-height: 140%;
    }
  }
  .title {
    font-size: $text-5a;
  }
  .icon {
    display: block;
    position: absolute;
    top: 0.75rem;
    left: 1rem;
    width: 32px;
  }
  .button {
    margin: 0.5rem 0 0;
    padding: 0;
    min-height: 1rem;
    font-size: $text-6;
    font-weight: 600;
    color: $color-feed-purple;
    white-space: nowrap;
  }
  @include breakpoint('lg') {
    margin-bottom: 60px;
    .icon {
      position: static;
    }
    .content {
      gap: 1rem;
      padding: 1rem 2rem 1rem 0.5rem;
      border-radius: 0 5px 5px 0;
      margin-left: 0.5rem;
      .title {
        font-size: $text-5;
        line-height: 120%;
      }
      .body {
        font-size: $text-5a;
        line-height: 120%;
      }
      &:after {
        display: block;
      }
    }
    .button {
      margin-top: 0;
      min-height: 40px;
      background-color: $color-feed-purple;
      color:  $color-white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: $text-5a;
      padding: 0.5rem 1.5rem;
      border-radius: 29px;
      transition: background-color 0.3s ease-in-out;
      &:hover {
        background-color: $color-feed-pink;
      }
    }
  }
}
