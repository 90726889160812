@import '../../../styles/variables';
@import '../../../styles/mixins';

.shoppingPosts {
  display: flex;
  gap: $text-3;

  @include breakpoint('sm') {
    display: block;
  }

  h2 { // visually hidden
    border: 0;
    display: block;
    height: 0;
    overflow: hidden;
    position: absolute;
    text-align: left;
    text-indent: -9999em;
    width: 0;
  }
}
