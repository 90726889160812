@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/fonts';

.splash {
  display: grid;
  grid-row-gap: $space-3;

  @include breakpoint('sm') {
    grid-template-columns: 1fr 1fr;
  }

  @include breakpoint('lg') {
    grid-template-columns: 0.8fr 1.13fr 1.92fr;
    padding: 0 $space-2;
  }
}

.trendingPosts {
  grid-area: 1 / 1;
  position: relative;
  border-bottom: 1px solid var(--color-primary-line);
  padding: 0 $space-2;

  @include breakpoint('sm') {
    grid-area: 1 / 1 / 2 / 3;
  }

  @include breakpoint('lg') {
    grid-area: 1 / 1 / 2 / 4;
    padding: 0;
  }
}

.funModule {
  grid-area: 2 / 1;
  border-bottom: 1px solid var(--color-primary-line);
  padding: 0 $space-2 $space-1;
  overflow: hidden;

  @include breakpoint('sm') {
    grid-area: 2 / 1 / 3 / 3;
    padding-bottom: $space-3;
  }

  @include breakpoint('lg') {
    grid-area: 2 / 3 / 3 / 4;
    padding-left: $space-3;
    padding-right: 0;
    border-bottom: initial;
    padding-bottom: initial;
  }
}

.momentOfTheDay {
  grid-area: 3 / 1;
  border-bottom: 1px solid var(--color-primary-line);
  padding: 0 $space-2 $space-3;
  contain: inline-size;

  @include breakpoint('sm') {
    grid-area: 3 / 1 / 4 / 2;
    border-bottom: initial;
    padding-bottom: initial;
    border-right: 1px solid var(--color-primary-line);
    padding-right: $space-3;
  }

  @include breakpoint('lg') {
    padding-left: 0;
    grid-area: 2 / 1 / 3 / 2;
  }
}

.shoppingPosts {
  grid-area: 4 / 1;
  padding: 0 $space-2 0;
  flex-direction: column;

  @include breakpoint('sm') {
    grid-area: 3 / 2 / 4 / 3;
    padding: $space-2 0 0 $space-3;
  }

  @include breakpoint('lg') {
    grid-area: 2 / 2 / 3 / 3;
    border-right: 1px solid var(--color-primary-line);
    padding: 0 $space-3;
  }
}
