@import '../../styles/variables';
@import '../../styles/mixins';

.sponsoredBar {
  color: var(--sponsorshipHighlightContrastColor, $color-black);
  background-color: var(--sponsorshipHighlightColor, $color-white);
  @include breakpoint('lg') {
    max-width: calc(1240px + 2rem);
    padding-left: $space-6;
    margin: 0 auto;
    color: $color-black;
    background-color: $color-white;
  }
}
.container {
  padding: $space-3a $space-2;
  @include breakpoint('lg') {
    margin: 0 auto;
    max-width: calc(1096px + 4rem);
    padding-bottom: 0;
    display: flex;
    align-items: center;
  }
}
.sponsor {
  margin: 0;
  a {
    color: var(--sponsorshipHighlightContrastColor, $color-black);
  }
  span span {
    opacity: 1;
    color: var(--sponsorshipHighlightContrastColor, $color-black);
  }
  @include breakpoint('lg') {
    a {
      color: $color-black;
    }

    span span {
      color: $color-gray-7;
    }
  }
}
.title {
  font-size: $text-5;
  line-height: 1;
  font-weight: 800;
  margin-bottom: $space-1;
  @include breakpoint('lg') {
    margin: 0 5px 0 0;
    font-size: $text-5a;
  }
}
.description {
  margin-bottom: $space-1;
  font-size: $text-5;
  line-height: 120%;
  @include breakpoint('lg') {
    font-size: $text-5a;
    margin: 0 $space-1a 0 0;
  }
}
.action {
  display: flex;
  align-items: center;
  @include breakpoint('lg') {
    flex-direction: row-reverse;
  }
}
.cta {
  display: flex;
  align-items: center;
  border-radius: 18px;
  background-color: var(--sponsorshipHighlightContrastColor, $color-black);
  color: var(--sponsorshipHighlightColor, $color-white);
  font-weight: $bold;
  font-size: $text-6;
  line-height: 1;
  padding: $space-1 $space-1a;
  margin-right: $space-1a;
  border: none;
  transition: color 150ms, background-color 150ms, border-color 150ms;
  .icon {
    path {
      transition: fill 150ms;
      fill: var(--sponsorshipHighlightColor, $color-white);
    }
  }
  @include breakpoint('lg') {
    margin: 0 0 0 $space-2;
    background-color: $color-white;
    color: $color-feed-purple;
    border: 2px solid $color-feed-purple;
    .icon {
      path {
        fill: $color-feed-purple;
      }
    }
    &:hover {
      opacity: 1;
      border-color: var(--sponsorshipHighlightColor, $color-black);
      color: var(--sponsorshipHighlightColor, $color-black);
      .icon {
        path {
          fill: var(--sponsorshipHighlightColor, $color-black);
        }
      }
    }
    &:active {
      background-color: var(--sponsorshipHighlightColor, $color-white);
      color: var(--sponsorshipHighlightContrastColor, $color-black);
      border-color: var(--sponsorshipHighlightColor, $color-black);
      .icon {
        path {
          fill: var(--sponsorshipHighlightContrastColor, $color-black);
        }
      }
    }
  }
}
.icon {
  width: 14px;
  height: auto;
  display: block;
  margin-right: 5px;
}

.sponsor {
  margin-bottom: 0;
  color: $color-black;
  font-size: $space-2;
}
