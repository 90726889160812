@import '../../styles/variables';
@import '../../styles/mixins';
@import 'bf-solid/_lib/solid-helpers';

.nav {
  position: fixed;
  bottom: 0;
  z-index: 100;
  padding: $space-3 calc($space-2 - $space-05);
  width: 100%;

  @include fullWidthPseudoElement(before, (
    border-top: 1px solid $color-feed-gray-1,
    background-color: rgba(255, 255, 255, 0.9),
    backdrop-filter: blur(12px)
  ));

  @include breakpoint('md') {
    bottom: auto;
    top: calc(var(--sticky-elements-offset-top, 0px) - 16px);
    position: sticky;
    &:before {
      border-top: none;
      border-bottom: 1px solid $color-feed-gray-1,
    }
  }

  @include solid-breakpoint('lg') {
    top: var(--sticky-elements-offset-top, 0px);
  }

  @mixin activeButtonStyles($backgroundColor: $color-feed-purple) {
    background-color: $backgroundColor;
    color: white;
    svg {
      fill: white;
    }
  }

  ul {
    list-style: none;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(auto-fit, minmax(auto, 1fr));
    width: 100%;
    gap: $space-1a;
    padding: 0;
    max-width: 100vw;
    overflow-x: auto;
  }

  &[data-content-transition-state]:not([data-content-transition-state="pending"]) {
    [data-content-nav-state="active"] {
      @include activeButtonStyles();
      &:hover {
        outline: 2px solid $color-feed-purple;
      }
      &[data-content-nav-name="tab_sponsored"] {
        background-color: var(--sponsorshipHighlightColor, $color-feed-purple);
        outline-color: var(--sponsorshipHighlightColor, $color-feed-purple);
        color: var(--sponsorshipHighlightContrastColor, $color-black);
        svg {
          path {
            fill: var(--sponsorshipHighlightContrastColor, $color-black);
          }
        }
      }
    }
  }
  &[data-content-transition-state="pending"] {
    button {
      pointer-events: none;
    }
  }

  button {
    cursor: pointer;
    position: relative;
    border-radius: 100vw;
    padding: $space-1a $space-2;
    color: $color-feed-purple;
    outline: 2px solid $color-feed-purple;
    outline-offset: -2px;
    font-size: $text-5a;
    font-weight: 700;
    display: flex;
    align-items: center;
    background-color: $color-white;
    justify-content: center;
    width: 100%;
    transition-property: background-color, color, outline-color;
    transition-duration: 150ms;
    white-space: nowrap;

    svg,
    img {
      margin: 0 $space-1 0 -#{$space-1};
      height: 14px;
      width: auto;
      z-index: 1;
    }

    svg {
      fill: $color-feed-purple;
      transition: fill 150ms;
    }

    span {
      margin-right: -#{$space-1};
      z-index: 1;
      font-weight: $bold;
      white-space: nowrap;
    }
    &:hover {
      @include activeButtonStyles($color-feed-pink);
      outline: 2px solid $color-feed-pink;
    }
    &[data-content-nav-state="next"] {
      @include activeButtonStyles();
      outline-color: $color-feed-purple;
      &[data-content-nav-name="tab_sponsored"] {
        background-color: var(--sponsorshipHighlightColor, $color-feed-purple);
        outline-color: var(--sponsorshipHighlightColor, $color-feed-purple);
        color: var(--sponsorshipHighlightContrastColor, $color-black);
        svg {
          path {
            fill: var(--sponsorshipHighlightContrastColor, $color-black);
          }
        }
      }
    }
    &[data-content-nav-name="tab_sponsored"] {
      &:hover {
        color: var(--sponsorshipHighlightColor, $color-black);
        outline-color: var(--sponsorshipHighlightColor, $color-black);
        background-color: $color-white;
        svg {
          path {
            fill: var(--sponsorshipHighlightColor, $color-black);
          }
        }
      }
    }
  }
  @include breakpoint('md') {
    padding: $text-4a $space-2;

    ul {
      gap: $space-2;
      width: fit-content;
      grid-template-columns: auto;
    }

    button {
      font-size: $text-5;
      width: auto;

      svg {
        height: $space-2;
        margin: 0 $space-05 0 0;
      }
      span {
        margin-right: 0;
      }
    }
  }
}

:global(.sponsorship-takeover) {
  // Prevent full width nav bar when there is a takeover
  .nav {
    overflow: hidden;
  }
}
