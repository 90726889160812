@import '../../styles/variables';
@import '../../styles/mixins';

.contentContainer {
  grid-template-columns: 1.55fr 1fr;
  grid-template-rows: auto auto 1fr;
  grid-column-gap: 0;
  grid-row-gap: 0;
  align-items: start;
  grid-template-areas:
    "top      sidebar"
    "header   sidebar"
    "content  sidebar";

  margin-bottom: $space-3;

  &:last-child {
    margin-bottom: calc($space-5 + var(--fixed-elements-offset-bottom, 0px));
  }

  // When multiple zones are rendered, each zone may be rendered with a `.contentContainer`. This
  // rule ensures that the header is not too close to the previous content while ignoring the first
  // header.
  &:not(:first-of-type):has(> :first-child.header) {
    margin-top: $space-5;
  }

  @include breakpoint('lg') {
    display: grid;
  }
}

.top {
  grid-area: top;
  padding: 0 $space-2;
  margin-bottom: $space-5;
}

.content {
  grid-area: content;
  list-style: none;
  background-color: $color-white;
  padding: 0 $space-2;
  max-width: 100vw;

  > li {
    margin-bottom: $space-3;
  }

  @include breakpoint('lg') {
    // When an inline ad is rendered as the first item in a grouping of content, reduce it's top
    // margin. This reduces the spacing when a full width ad precedes it.
    > li:first-child {
      aside {
        margin-top: 0;
      }
    }

    // When an inline ad or any type of item is rendered as the last item in a grouping of content,
    // reduce it's bottom margin. This reduces the spacing when a full width ad follows it.
    > li:last-child {
      margin-bottom: 0;

      article,
      aside {
        margin-bottom: 0;
      }
    }
  }
}

@include breakpoint('md') {
  .two_columns {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: $space-3;

    // Any list item that is not feed content (ads, "show more" button) should span both columns.
  > li:not(.feedItem) {
      grid-column: span 2;
    }
  }
}

.header {
  grid-area: header;
  padding: 0 $space-2;
  margin-bottom: $space-3;
  @include breakpoint('lg') {
    margin-bottom: $space-3b;
  }
}

.headline {
  font-size: $text-3b;
  display: flex;
  align-items: center;

  svg,
  img {
    width: auto;
    height: 19px;
    margin-right: $space-1;
  }

  @include breakpoint('lg') {
    font-size: $text-2;

    svg,
    img {
      height: $text-3;
      margin-right: $space-1a;
    }
  }
}

main:global(.home-page) {
  .header {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
}

main:global(.topic-page) {
  // Different handling due to the splash element with negative margins (where it overlaps the topic
  // header).
  .sidebar {
    padding-top: $space-3b;
  }
}

.sidebar {
  position: relative;
  display: none;
  align-items: center;
  flex-direction: column;
  height: 100%;
  padding: 0 $space-3b;
  grid-area: sidebar;

  @include breakpoint('lg') {
    display: flex;
  }
}

.sidebarAd {
  min-width: 300px;
  width: 100%;
}

.loadMoreButton {
  display: flex;
  transition: opacity 150ms;

  &.fetchingMoreFeedItems {
    opacity: .5;
  }

  button {
    background-color: $themeColor1;
    color: $themeTextColor;
    font-weight: $bold;
    margin: $space-2 auto;

    &:hover {
      background-color: $themeColor2;
      color: $themeTextColor;
    }
  }
}

.ctaWrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: $space-4;
  padding: $space-2 0;
  border-top: 1px solid $color-feed-gray-1;

  a {
    font-weight: $bold;
    color: $color-feed-purple;
    transition: color 150ms;
    display: flex;
    align-items: center;
    svg {
      margin-left: 2px;
      path {
        transition: stroke 150ms;
      }
    }
    &:hover {
      color: $color-feed-pink;
      svg {
        path {
          stroke: $color-feed-pink;
        }
      }
    }
  }
}
