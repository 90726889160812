@import '../../../../styles/variables';
@import '../../../../styles/mixins';
.actionWrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .subTitle {
    color: $color-feed-purple;
    font-weight: $bold;
    margin-bottom: 0;
    padding-right: $space-2;
    flex: 1 1 auto;
    @include breakpoint('lg') {
      font-size: $text-6;
    }
  }
}
.actions {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  flex: 0 0 96px;
}
.label {
  color: $color-gray-7;
  font-size: $text-6;
  margin-right: 12px;
  &:last-of-type {
    margin-right: 0;
  }
}
.actionBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 32px;
  height: 32px;
  padding: 0;
  .icon {
    display: block;
    width: 100%;
    height: auto;
    path {
      stroke: $color-feed-purple;
      transition: stroke 0.3s ease-in-out;
    }
  }
  &:hover:not(.active) {
    .icon {
        path {
        stroke: $color-feed-pink;
      }
    }
  }
  &.dislike {
    transform: rotate(180deg);
  }
  &.active {
    .icon {
      fill: $color-feed-purple;
    }
  }
  @include breakpoint('lg') {
    height: 28px;
    width: 28px;
  }
}
